declare const API_URL: string
import axios from 'axios'
import { ReadAccountToken } from '../../HandleToken'
import { CheckAccountToken, CheckAPIURL } from '../Check'

/**
 * 對象：SOHO會員
 * 動作：更新個人資訊
 */
interface UpdateMemberInputData {
  picture?: string
  description?: string
  cover_picture?: string
  nick_name?: string
  personal_id?: string
  bio?: string
}

export const updateMember = async (inputData: UpdateMemberInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
          mutation ($inputData: UpdateMemberInputData!){
            updateMember(inputData: $inputData) {            
              message
              status
              errors
            }
          }`,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateMember
    const errors = res.data.data.updateMember.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of updateMember', err)
  }
  return result
}

/**
 * 對象：任何人
 * 動作：查看個人接案履歷
 *      (當 checked 為 true 時，則僅查詢 personal_id 是否存在)
 */
interface GetProfileFromSOHOInputData {
  checked: boolean
  personal_id: string
}

export const getProfileFromSOHO = async (
  inputData: GetProfileFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
          query ($inputData: GetProfileFromSOHOInputData!){
            getProfileFromSOHO(inputData: $inputData) {    
              payload{
                existed
              }        
              message
              status
              errors
            }
          }`,
      variables: {
        inputData,
      },
    })
    result = res.data.data.getProfileFromSOHO
    const errors = res.data.data.getProfileFromSOHO.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of getProfileFromSOHO', err)
  }
  return result
}

/**
 * 對象：任何人
 * 動作：查詢所有接案履歷
 */
interface GetMemberProfilesFromGuessInputData {
  offset: number
  limit: number
  orderBy?: {
    field: string
    sort: string
  }
  keyword?: string
}

export const getMemberProfilesFromGuess = async (
  inputData: GetMemberProfilesFromGuessInputData
) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
          query ($offset: Int $limit: Int $orderBy: OrderBy $keyword: String){
            getMemberProfilesFromGuess(offset: $offset limit: $limit orderBy:$orderBy keyword:$keyword) {    
              payload{
                 id
                 personal_id
                 picture
                 description
                 cover_picture
                 nick_name
                 bio
              }        
              pagination{
                count
                limit
                offset
              }
              message
              status
              errors
            }
          }`,
      variables: {
        offset: inputData.offset,
        limit: inputData.limit,
        orderBy: inputData.orderBy,
        keyword: inputData.keyword,
      },
    })
    result = res.data.data.getMemberProfilesFromGuess
    const errors = res.data.data.getMemberProfilesFromGuess.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of getMemberProfilesFromGuess', err)
  }
  return result
}

/**
 * 對象：任何人
 * 動作：查詢所有接案履歷
 */
interface GetMemberProfileFromGuessInputData {
  personal_id: string
}

export const getMemberProfileFromGuess = async (
  inputData: GetMemberProfileFromGuessInputData
) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
          query ($inputData: GetMemberProfileFromGuessInputData){
            getMemberProfileFromGuess(inputData: $inputData) {    
              payload{
                 id
                 personal_id
                 picture
                 description
                 cover_picture
                 nick_name
                 bio
                 verified_state
              }        
              message
              status
              errors
            }
          }`,
      variables: {
        inputData,
      },
    })
    result = res.data.data.getMemberProfileFromGuess
    const errors = res.data.data.getMemberProfileFromGuess.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of getMemberProfileFromGuess', err)
  }
  return result
}
